import React, { useEffect } from 'react';
import Section from '../common/Section';
import Image from '../common/Image';
import theme from '../mui-overrides';
import Slider from 'react-slick';
import { Hidden } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import styles from '../../styles/modules/components/promotion.module.scss';
import { topPromotionalSliderBannerClick } from '../../tracking/segment/homePage';

interface PromotionalBannerProps {
    banners:any
}


const PromotionalBanner = (props:PromotionalBannerProps) => {
    const settings = {
        dots:false,
        infinite:true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow:<NextArrow/>,
        // prevArrow:<PrevArrow/>,
        autoplay:true,
        arrows:false,
        autoplaySpeed:3000
      };
      useEffect(() => {},[props.banners])
      const bannerClick = (banner_position, banner_link) => {
        topPromotionalSliderBannerClick({'banner_position':banner_position, 'banner_link':banner_link})
      }
    return (
        <Section className={styles.banner}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {props.banners && <>
                    <Hidden only={['md','lg','xl']}>
                        {
                            props.banners.length == 1
                            &&
                            <a className={styles.target_link} href={props.banners[0].link ? props.banners[0].link : '#'} onClick={()=>{bannerClick(1,props.banners[0].link)}}>
                                <Image src={props.banners[0].mobile_image}  alt={props.banners[0].name} width="765" height="340"/>
                            </a>
                        }
                        
                        {
                            props.banners.length > 1
                            &&
                            <Slider {...settings}>
                                {
                                     props.banners.map((banner,index) => {
                                
                                        return (
                                            <a className={styles.target_link} href={banner.link ? banner.link  : '#'} onClick={()=>{bannerClick(index+1,banner.link)}}>
                                                <Image src={banner.mobile_image}  alt={banner.name} width="765" height="340"/>
                                            </a>
                                        )
                                    })
                                }
                             </Slider>
                        }
                    </Hidden>
                    <Hidden only={['xs','sm','lg','xl']}>
                    {
                            props.banners.length == 1
                            &&
                            <a className={styles.target_link} href={props.banners[0].link ? props.banners[0].link : '#'} onClick={()=>{bannerClick(1,props.banners[0].link)}}>
                                <Image src={props.banners[0].tablet_image} alt={props.banners[0].name} width="960" height="300"/>
                            </a>
                    }
                    {
                         props.banners.length > 1
                         &&
                         <Slider {...settings}>
                             {
                                  props.banners.map((banner,index) => {
                             
                                     return (
                                        <a className={styles.target_link} href={banner.link ? banner.link  : '#'} onClick={()=>{bannerClick(index+1,banner.link)}}>
                                            <Image src={banner.tablet_image} alt={banner.name} width="960" height="300"/>
                                        </a>
                                     )
                                 })
                             }
                          </Slider>
                    }
                    </Hidden>
                    <Hidden only={['xs','md','sm']}>
                        {
                              props.banners.length == 1
                              &&
                              <a className={styles.target_link} href={props.banners[0].link ? props.banners[0].link : '#'} onClick={()=>{bannerClick(1,props.banners[0].link)}}>
                                <Image src={props.banners[0].desktop_image} alt={props.banners[0].name} width="1920" height="320"/>
                              </a>
                        }
                         {
                         props.banners.length > 1
                         &&
                         <Slider {...settings}>
                             {
                                  props.banners.map((banner,index) => {
                             
                                     return (
                                        <a className={styles.target_link} href={banner.link ? banner.link  : '#'} onClick={()=>{bannerClick(index+1,banner.link)}}>
                                            <Image src={banner.desktop_image} alt={banner.name} width="1920" height="320"/>
                                        </a>
                                     )
                                 })
                             }
                          </Slider>
                    }
                    </Hidden>
                    </>}
                </ThemeProvider>
            </StyledEngineProvider>
        </Section>
    );
}


export default PromotionalBanner;